(function (angular, app) {
    'use strict';

    var DEFAULT_SEARCH_PARAMS = {
        filters: {
            must: {
                exists: ['family.id', 'family.categoriesPaths.id', 'branch.regularPrice'],
                term: {
                    'branch.isActive': true,
                    'branch.isVisible': true
                }
            },
            mustNot: {
                term: {
                    'branch.regularPrice': 0
                }
            }
        }
    };

    var COUNTER = 0;

    app.directive('spSearchAutocomplete', ['$location', '$rootScope', '$timeout', '$q', '$state', 'Util', 'Config', 'FilterManager', 'User', 'Api', 'Cart', 'DataLayer',
        function($location, $rootScope, $timeout, $q, $state, util, Config, FilterManager, User, api, Cart, DataLayer) {
            return {
                restrict: 'E',
                replace: true,
                templateUrl: 'template/directives/sp-search-autocomplete/index.html',
                scope: {
                    model: '=spModel',
                    threshold: '=?'
                },
                controllerAs: 'searchAutoCompleteCtrl',
                controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {
                    var searchAutoCompleteCtrl = this,
                        _parentElement = angular.element($element[0].parentNode || $element[0].parentElement),
                        _body = document.querySelector('body'),
                        _inputElement = angular.element(null),
                        _inputNgModelController,
                        _reloadAutoComplete,
                        _showOptionsWatcher,
                        _chosenOption,
                        _isRetailerPremiumReplacementsEnabled = Config.retailer.settings.enablePersonalReplacement,
                        _defaultOptionsCache,
                        isRtl = Config.language.direction == 'rtl';

                    searchAutoCompleteCtrl.shown = false;
                    searchAutoCompleteCtrl.elementId = 'sp_search_autocomplete';
                    searchAutoCompleteCtrl.brandsElementId = 'drag_to_scroll_brands';
                    searchAutoCompleteCtrl.categoriesElementId = 'drag_to_scroll_categories';
                    searchAutoCompleteCtrl.brandsElement = {};
                    searchAutoCompleteCtrl.categoriesElement = {};
                    searchAutoCompleteCtrl.searchByFilters = searchByFilters;
                    searchAutoCompleteCtrl.search = search;
                    searchAutoCompleteCtrl.startDragging = startDragging
                    searchAutoCompleteCtrl.stopDragging = stopDragging;
                    searchAutoCompleteCtrl.moveEvent = moveEvent;
                    searchAutoCompleteCtrl.openOrderProduct = openOrderProduct;
                    searchAutoCompleteCtrl.getLine = getLine;
                    searchAutoCompleteCtrl.carouselPrev = carouselPrev;
                    searchAutoCompleteCtrl.carouselNext = carouselNext;


                    var _inputAttrs = {
                        'role': 'combobox',
                        'aria-autocomplete': 'list',
                        'aria-expanded': 'false',
                        'aria-haspopup': 'listbox',
                        'aria-owns': searchAutoCompleteCtrl.elementId
                    };

                    if (angular.isUndefined($scope.threshold)) {
                        $scope.threshold = 300;
                    }

                    _parentElement.addClass('auto-complete-wrapper');

                    function openOrderProduct(product) {
                        product && util.openProduct(angular.copy(product));
                    }

                    function startDragging(event, flag, elementId, $element) {
                        var element = _getDragElement(elementId, $element);
                        searchAutoCompleteCtrl.mouseDown = true;
                        searchAutoCompleteCtrl.startX = event.pageX - element.offsetLeft;
                        searchAutoCompleteCtrl.scrollLeft = element.scrollLeft;
                    }

                    function stopDragging(event, flag) {
                        searchAutoCompleteCtrl.mouseDown = false;

                    }

                    function moveEvent(event, elementId) {
                        event.preventDefault();

                        if (!searchAutoCompleteCtrl.mouseDown) {
                            return;
                        }

                        var element = _getDragElement(elementId);
                        var x = event.pageX - element.offsetLeft;
                        var scroll = x - searchAutoCompleteCtrl.startX;
                        element.scrollLeft = searchAutoCompleteCtrl.scrollLeft - scroll;
                    }

                    function _getDragElement(elementId) {
                        if (!searchAutoCompleteCtrl[elementId]) {
                            searchAutoCompleteCtrl[elementId] =  document.getElementById(elementId || 'drag_to_scroll');
                        }

                        return searchAutoCompleteCtrl[elementId];
                    }

                    function getOptions() {
                        var isDefaultOptions = !$scope.model || $scope.model.length < 3;
                        var params = angular.copy(DEFAULT_SEARCH_PARAMS);
                        params.from = 0;
                        params.size = 10;
                        params.query = $scope.model;
                        params.languageId = Config.language.id;
                        params.isSearch = true;
                        params.userId = User.data && User.data.id
                        if (Config.retailer.settings.showUnavailableProducts !== 'true') {
                            params.filters.mustNot.term = {
                                'branch.regularPrice': 0,
                                "branch.isOutOfStock": true
                            }
                        }
                        else {
                            if (Config.retailer.settings.isOutOfStockDuration === 'true') {
                                params.filters.bool = {
                                    "should": [
                                        {
                                            "bool": {
                                                "must_not": {
                                                    "exists": {
                                                        "field": "branch.outOfStockShowUntilDate"
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "bool": {
                                                "must": [
                                                    {
                                                    "range": {
                                                        "branch.outOfStockShowUntilDate": {
                                                        "gt": "now"
                                                        }
                                                    }
                                                    },
                                                    {
                                                    "term": {
                                                        "branch.isOutOfStock": true
                                                    }
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            "bool": {
                                                "must": [
                                                    {
                                                    "term": {
                                                        "branch.isOutOfStock": false
                                                    }
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            }
                        }
						FilterManager.parsePermanentProductTags(params, false);
                        FilterManager.parseFilters(params, []);

                        _chosenOption = $scope.model;

                        return $q.resolve().then(function () {
                            if (isDefaultOptions && !!_defaultOptionsCache) {
                                return _defaultOptionsCache;
                            }

                            return api.request({
                                url: '/v2/retailers/:rid/branches/:bid/products/autocomplete',
                                method: 'GET',
                                params: params
                            });
                        }).then(function (data) {
                            if (isDefaultOptions) {
                                _defaultOptionsCache = data;
                            }
                            searchAutoCompleteCtrl.isSearchResult = $scope.model && $scope.model.length > 2;
                            searchAutoCompleteCtrl.title = data.title && data.title[params.languageId];
                            searchAutoCompleteCtrl.customerType = data.customerType;
                            searchAutoCompleteCtrl.rules = data.suggestions && data.suggestions.rules;
                            searchAutoCompleteCtrl.products = _productsMapping(data.suggestions && data.suggestions.suggestProducts);
                            searchAutoCompleteCtrl.highlight = null;
                            searchAutoCompleteCtrl.brandsHighlight = null;


                            if (searchAutoCompleteCtrl.isSearchResult) {
                                searchAutoCompleteCtrl.isBrandSuggestionsActive = data.isBrandSuggestionsActive;
                                searchAutoCompleteCtrl.brandSuggestionsTitle = data.brandSuggestionsTitle && data.brandSuggestionsTitle[params.languageId];
                                searchAutoCompleteCtrl.highlight = [];
                                searchAutoCompleteCtrl.brandsHighlight = [];

                                if (data.suggestions && data.suggestions.suggestFilters) {
                                    if (data.suggestions.suggestFilters.categories && data.suggestions.suggestFilters.categories.length) {
                                        Array.prototype.push.apply(searchAutoCompleteCtrl.highlight, data.suggestions.suggestFilters.categories);
                                    }

                                    if (data.suggestions.suggestFilters.brands && data.suggestions.suggestFilters.brands.length) {
                                        Array.prototype.push.apply(searchAutoCompleteCtrl.brandsHighlight, data.suggestions.suggestFilters.brands);
                                    }
                                }
                            }
                        });
                    }

                    function _productsMapping(suggestProducts) {
                        var products;
                        if (suggestProducts.products) {
                            if (_isRetailerPremiumReplacementsEnabled) {
                                suggestProducts.products.forEach(function(sub) {
                                    if (sub.branch && sub.branch.isOutOfStock) {
                                        User.getReplacementSuggestions([sub.id], []).then(function(data) {
                                            if (data.length > 0 && data[0].suggestions) {
                                                sub._suggestions = data[0].suggestions
                                            }
                                        })
                                    }
                                });
                            }

                            products = suggestProducts.products;
                        } else {
                            products = (suggestProducts.items || []).map(function (item) {
                                return item && item.product || item;
                            });
                        }

                        return Cart.getProducts(products);
                    }

                    function searchByFilters(key, value, name) {
                        searchAutoCompleteCtrl.filters = {};
                        if (key && value) {
                            searchAutoCompleteCtrl.filters[key] = value;
                        }
                        if(key === 'brand' && value && value[0]) {
                            searchAutoCompleteCtrl.filters = searchAutoCompleteCtrl.filters || {};
                            searchAutoCompleteCtrl.filters.queryInBrand = $scope.model;
                        }

                        search('', name);
                    }

                    function search(item, title) {
                        var filter,
                            query = $scope.model;
                        if (item === 'specials') {
                            filter = 'specials';
                        } else if(searchAutoCompleteCtrl.filters && searchAutoCompleteCtrl.filters.brand) { // Search by brand - remove search term
                            query = title ? '' : item || $scope.model;
                        }

                        searchAutoCompleteCtrl.postChooseOptionClick = true;

                        $state.go('app.searchProducts', {
                            query: query,
                            filter: filter,
                            filters: searchAutoCompleteCtrl.filters || undefined,
                            sort: undefined,
                            title: title || undefined
                        });

                        searchAutoCompleteCtrl.filters = undefined
                    }


                    function _reloadOptions(setInputInFocus) {
                        if (_reloadAutoComplete) {
                            $timeout.cancel(_reloadAutoComplete);
                        }
                        searchAutoCompleteCtrl.postChooseOptionClick = false;
                        _chosenOption = null;

                        _reloadAutoComplete = $timeout(function() {
                                return getOptions().then(function () {
                                    if (setInputInFocus) {
                                        searchAutoCompleteCtrl.inFocus = true;
                                    }
                                }).catch(function(err) {
                                });
                        }, $scope.threshold);
                    }

                    function _isElementFixed(element) {
                        element = angular.element(element)[0];
                        var res = '';
                        if (window.getComputedStyle && angular.isFunction(window.getComputedStyle)) {
                            res = window.getComputedStyle(element).getPropertyValue('position');
                        } else if (element.currentStyle && element.currentStyle.position) {
                            res = element.currentStyle.position;
                        }
                        return res === 'fixed';
                    }

                    function _callSetIsOutOfScreen() {
                        $timeout(_setIsOutOfScreen, 0);
                        $timeout(_setIsOutOfScreen, 500);
                    }

                    function _setIsOutOfScreen() {
                        var currentElement = _parentElement[0],
                            top = 0;
                        do {
                            top += currentElement.offsetTop;
                            currentElement = currentElement.offsetParent;
                        } while (!!currentElement && !_isElementFixed(currentElement));

                        var bottom = top + _parentElement[0].offsetHeight + $element.prop('offsetHeight');

                        if (bottom > _body.offsetHeight) {
                            $element.addClass('out-of-screen');
                        } else {
                            $element.removeClass('out-of-screen');
                        }
                    }

                    function _apply() {
                        $scope.$applyAsync();
                    }

                    function _inputKeyDown(event) {
                        if (event.which === 13) {
                            event.preventDefault();
                            event.stopImmediatePropagation();
                            search();
                            return _apply();
                        }

                        var toAdd = 0;
                        switch (event.which) {
                            case 40:
                                toAdd = 1;
                                break;
                            case 38:
                                toAdd = -1;
                                break;
                        }
                    }

                    function _elementFocus() {
                        searchAutoCompleteCtrl.autocompleteElementFocus = true;
                    }

                    function _inputFocus() {
                        _reloadOptions(true);
                        _apply();
                        _callSetIsOutOfScreen();
                    }

                    function _inputBlur() {
                        $timeout(function() {
                            //  if (searchAutoCompleteCtrl.autocompleteElementFocus || !searchAutoCompleteCtrl.inFocus || searchAutoCompleteCtrl.inFocus < 0) {
                            //     return;
                            // }


                               searchAutoCompleteCtrl.inFocus = false;
                        });
                    }

                    function _bindInput() {
                        $element.bind('focusin', _elementFocus);
                        _inputElement.bind('keydown', _inputKeyDown);
                        _inputElement.bind('focus', _inputFocus);
                        _inputElement.bind('blur', _inputBlur);

                        angular.forEach(_inputAttrs, function(value, key) {
                            _setInputAttr(key, value);
                        });
                    }

                    function _setInputAttr(key, value) {
                        _inputElement && _inputElement[0].setAttribute(key, value);
                    }

                    var watcher = $scope.$watch(function() {
                        return _parentElement[0].querySelector('input[ng-model="' + $attrs.spModel + '"]');
                    }, function(newValue) {
                        if (!newValue) return;

                        watcher();
                        _inputElement = angular.element(newValue);
                        _inputNgModelController = _inputElement.data('$ngModelController');
                        _bindInput();
                    });

                    $scope.$watch('model', function(newVal) {
                        if (_chosenOption === newVal) {
                            return;
                        }

                        _reloadOptions(true);
                    });

                    var _reloadListener;

                    $scope.$on('$destroy', function() {
                        _reloadListener && _reloadListener();
                    });

                    _showOptionsWatcher = '(searchAutoCompleteCtrl.inFocus || searchAutoCompleteCtrl.autocompleteElementFocus) && !searchAutoCompleteCtrl.postChooseOptionClick';

                    $scope.$watch(_showOptionsWatcher, function(newVal) {
                        _setShown(!!newVal);
                    });

                    function _setShown(value) {
                        var prevValue = searchAutoCompleteCtrl.shown;
                        searchAutoCompleteCtrl.shown = value;
                        _setInputAttr('aria-expanded', searchAutoCompleteCtrl.shown);
                        if (searchAutoCompleteCtrl.shown) {
                            _parentElement.addClass('auto-complete-options-shown');
                        } else {
                            _parentElement.removeClass('auto-complete-options-shown');
                        }
                    }

                    util.currentScopeListener($scope, $rootScope.$on('cart.lines.add', function () {
                        DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Add Items from search autocomplete box'}});
                    }));

                    function _onResize() {
                        _callSetIsOutOfScreen();
                    }

                    function _checkFocusElement(event) {

                        var parent = angular.element(document.querySelector(".dialog-wrapper"));
                        if (parent && !angular.isUndefined(parent[0]) && parent[0].contains(document.activeElement) || document.activeElement.id === 'main-search') {
                            return;
                        }
                        _inputBlur();
                        if (!searchAutoCompleteCtrl.autocompleteElementFocus) return;

                        var eventTarget = event && (event.target || event.srcElement || event.currentTarget);
                        searchAutoCompleteCtrl.autocompleteElementFocus = eventTarget && !!eventTarget.closest('.sp-search-autocomplete');
                    }

                    function _clearCache() {
                        _defaultOptionsCache = null;
                    }

                    function getLine(product) {
                        return util.getProductCartLine(product, product.isCaseMode);
                    }

                    function carouselPrev(elementId, options) {
                        var carouselItem = _getDragElement(elementId),
                            currentPosition = carouselItem.clientWidth + carouselItem.scrollLeft,
                            translate;

                        if (currentPosition < carouselItem.clientWidth) {
                            translate = currentPosition;
                        } else {
                            translate = (carouselItem.clientWidth);
                        }

                        options.hideNext = false;
                        carouselItem.scrollBy({ left: isRtl ? translate : translate * -1, behavior: 'smooth' });

                        $timeout(function() {
                            if (currentPosition - translate <= carouselItem.clientWidth) {
                                options.hidePrev = true;
                            }
                        });
                    }

                    function carouselNext(elementId, options) {
                        var carouselItem = _getDragElement(elementId),
                            currentPosition = carouselItem.clientWidth + carouselItem.scrollLeft,
                            translate;

                        if ((carouselItem.scrollWidth - currentPosition) < carouselItem.clientWidth) {
                            translate = carouselItem.scrollWidth - currentPosition;
                        } else {
                            translate = (carouselItem.clientWidth);
                        }

                        options.hidePrev = false;
                        if (currentPosition + translate === carouselItem.scrollWidth) {
                            options.hideNext = true;
                        }

                        carouselItem.scrollBy({ left: isRtl ? translate * -1 : translate, behavior: 'smooth' });
                    }

                    function _onHighlightChange(elementId, options, items) {
                        options.hidePrev = false;
                        options.hideNext = false;
                        options.showArrows = false;
                        $timeout(function() {
                            var element = _getDragElement(elementId);
                            if ((!element || !element.clientWidth) && items && items.length) {
                                searchAutoCompleteCtrl[elementId] = null;
                                _onHighlightChange(elementId, options, items);
                            } else if (element) {
                                element.scrollTo(0, 0);
                                options.hidePrev = true;
                                options.showArrows = element.scrollWidth > element.clientWidth;
                            }
                        });
                    }

                    $scope.$watch('searchAutoCompleteCtrl.highlight', function() {
                        if (!searchAutoCompleteCtrl.highlight || !searchAutoCompleteCtrl.highlight.length) {
                            return;
                        }
                        _onHighlightChange(searchAutoCompleteCtrl.categoriesElementId, searchAutoCompleteCtrl.categoriesElement, searchAutoCompleteCtrl.highlight);
                    });
                    $scope.$watch('searchAutoCompleteCtrl.brandsHighlight', function() {
                        if (!searchAutoCompleteCtrl.brandsHighlight || !searchAutoCompleteCtrl.brandsHighlight.length) {
                            return;
                        }
                        _onHighlightChange(searchAutoCompleteCtrl.brandsElementId, searchAutoCompleteCtrl.brandsElement, searchAutoCompleteCtrl.brandsHighlight);
                    });
                    document.addEventListener('focusin', _checkFocusElement);
                    angular.element(window).bind('resize', _onResize);
                    util.currentScopeListener($scope, $scope.$root.$on('login', _clearCache));
                    util.currentScopeListener($scope, $scope.$root.$on('logout', _clearCache));

                    util.destroyListeners($scope, function() {
                        _setShown(false);
                        angular.element(_inputElement).unbind('keydown', _inputKeyDown);
                        angular.element(_inputElement).unbind('focus', _inputFocus);
                        angular.element(_inputElement).unbind('blur', _inputBlur);
                        angular.element($element).unbind('focusin', _elementFocus);
                        angular.element(window).unbind('resize', _onResize);
                    });
                }]
            };
        }]);
})(angular, app);
