(function (angular, app) {
    'use strict';

    var FULL_SCREEN_CLASS_NAME = 'full-screen-image';
    var HIDE_SIDENAV_CLASS_NAME = 'separate-produce-hide-sidenav'
    var SEPARATE_PRODUCT_PAGE_CLASS_NAME = 'separate-product-wrapper'

    app.config([
        'SpDialogUrlManagerProvider', 'SP_URL_DIALOG_QUERY_PARAMS',
        function(SpDialogUrlManagerProvider, SP_URL_DIALOG_QUERY_PARAMS) {
            SpDialogUrlManagerProvider.dialog({
                queryParams: SP_URL_DIALOG_QUERY_PARAMS.PRODUCT,
                canShow: ['paramValue', function(paramValue) {
                    return !isNaN(paramValue);
                }],
                paramChangedEventName: 'productDialog.change',
                paramResolve: ['$q', 'Api', 'paramName', 'paramValue', 'savedParamData', function($q, Api, paramName, paramValue, savedParamData) {
                    paramValue = Number(paramValue);

                    if (paramName === 'product') {
                        if (savedParamData && savedParamData.product && savedParamData.product.id === paramValue) {
                            return $q.resolve(savedParamData);
                        }

                        return Api.request({
                            method: 'GET',
                            url: '/v2/retailers/:rid/branches/:bid/products/' + paramValue
                        }, {
                            fireAndForgot: true
                        }).then(function(product) {
                            return {product: product};
                        });
                    }

                    if (savedParamData && savedParamData.product && savedParamData.product.productId === paramValue) {
                        return $q.resolve(savedParamData);
                    }

                    return Api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/branches/:bid/products',
                        params: {
                            catalogProductId: paramValue
                        }
                    }, {
                        fireAndForgot: true
                    }).then(function(result) {
                        if (result.total > 0) {
                            return {product: result.products[0]};
                        } else {
                            return $q.reject(new Error('Product not found'));
                        }
                    });
                }],
                show: [
                    '$rootScope', 'Config', 'Util', 'Dialog', 'SpDialogUrlManager', 'value',
                    function($rootScope, Config, Util, Dialog, SpDialogUrlManager, value) {
                        var languageId = Config.language.id,
                            styleClass = ['product'];
                        if (value.product.nutritionFacts && value.product.nutritionFacts.length ||
                            value.product.data && value.product.data[languageId] && value.product.data[languageId].ingredients) {
                            styleClass.push('with-more-details');
                        }
                        if (value.product.images && value.product.images.length > 0) {
                            value.product.images = value.product.images.sort(function(a, b) {
                                var aSort = a.isDefault ? 0 : a.sort !== undefined ? a.sort+1 : 100,
                                    bSort =  b.isDefault ? 0 : b.sort !== undefined ? b.sort+1 : 100;
                                var s = aSort - bSort;
                                if(s === 0){
                                    s = a.id - b.id;
                                }
                                return s;
                            });
                        }

                        function _resetSeparateProductPage(){
                            var wrapper = angular.element(document.querySelector('.dialog-wrapper'));
                            var sidenav = angular.element(document.querySelector('.sidenav'));
                            var bubble = angular.element(document.querySelector('.home-page-bubble'));
                            wrapper.removeClass(SEPARATE_PRODUCT_PAGE_CLASS_NAME);
                            sidenav.removeClass(HIDE_SIDENAV_CLASS_NAME);
                            bubble.removeClass(HIDE_SIDENAV_CLASS_NAME);
                        }
                        var isSeparateProductPage = Config.retailer.settings.showProductInSeparatePage === 'true';

                        var promise = Dialog.show({
                            templateUrl: 'template/dialogs/product/index.html',
                            controller: 'ProductCtrl as productCtrl',
                            ariaLabelledby: 'product_dialog_title',
                            disableClosing: isSeparateProductPage,
                            styleClass: styleClass.join(' '),
                            resolve: {
                                product: value.product,
                                itemsData: value.itemsData
                            },
                            hide: SpDialogUrlManager.backClose
                        });

                        promise.finally(function() {
                            Util.updateMetaByProduct();
                            if (isSeparateProductPage){
                                _resetSeparateProductPage()
                            }
                        });

                        return promise;
                    }
                ]
            });
        }
    ]);

    app.controller('ProductCtrl', [
        '$scope', '$rootScope', '$filter', '$q', '$stateParams', '$state', '$sce', 'Api', 'Cart', 'User', 'Util', 'ShopListService',
        'Config', 'SpProductTags', 'Dialog', 'FilterManager', 'product', 'itemsData', 'PRODUCT_PAGE_CAROUSEL_CONTENT',
        'PRODUCT_TAG_TYPES', 'SUPERVISION_TAGS', 'IMAGE_TYPES', 'ZOOM_CONTROLS_ZOOM_OPTIONS', 'DataLayer','SpDialogUrlManager','$location',
        function ($scope, $rootScope, $filter, $q, $stateParams, $state, $sce,  Api, Cart, User, Util, ShopListService,
                  Config, SpProductTags, Dialog, FilterManager, product, itemsData, PRODUCT_PAGE_CAROUSEL_CONTENT,
                  PRODUCT_TAG_TYPES, SUPERVISION_TAGS, IMAGE_TYPES, ZOOM_CONTROLS_ZOOM_OPTIONS, DataLayer, SpDialogUrlManager, $location) {
            window.prerenderReady = true;

            var productCtrl = this,
                _imageFilter = $filter('image'),
                _productImageFilter = $filter('productImage'),
                _productImagesFilter = $filter('productImages'),
                _nameFilter = $filter('name');

            var _isRetailerPremiumReplacementsEnabled = Config.retailer.settings.enablePersonalReplacement;

            // init the product tag filters using the filters state param
            // similarly to the way it's done in all other filtered pages
            var _productTagsFilter = FilterManager.getProductTagsFilter(function() {}, $scope, false)
            FilterManager.setFiltersFromUrl({ productTag: _productTagsFilter }, $stateParams.filters);

            productCtrl.showPropertyError = null;

            productCtrl.openSuggestions = function (event) {
                back();
                if (event) {
                    event.stopImmediatePropagation();
                }

                Util.openSuggestionsDialog(productCtrl.product);
            }

            angular.extend(productCtrl, {
                similarProducts: {}, // sp-carousel set function on similarProducts
                createNewShopList: createNewShopList,
                tryLogin: tryLogin,
                saveToShopList: saveToShopList,
                getLine: getLine,
                nextProduct: nextProduct,
                trustedHtml: trustedHtml,
                previousProduct: previousProduct,
                openProduct: openProduct,
                loadSimilarProducts: loadSimilarProducts,
                setFullScreen: setFullScreen,
                image360ZoomOut: image360ZoomOut,
                showProductTags: showProductTags,
                onPropertyValueChanged: onPropertyValueChanged,
                navigateToCategory: navigateToCategory,
                back: back,
                pricingMethodToggle: pricingMethodToggle,
                switchProductShowImage: switchProductShowImage,
                switchProductShowImage_onMouseEnter: switchProductShowImage_onMouseEnter
            });

            productCtrl.similerProductsCarouselWatchers = {
                'cart.lines.add': function (carouselProducts) {
                    angular.forEach(carouselProducts, function (product, index) {
                        carouselProducts[index] = Cart.getProduct(product);
                    });
                }
            };

            // unshift exit from full screen into zoom options, will not be the default
            productCtrl.zoomControlsOptions = [_imageZoomOutFromFullScreen];
            // add the default zoom values
            Array.prototype.push.apply(productCtrl.zoomControlsOptions, ZOOM_CONTROLS_ZOOM_OPTIONS);
            // make the first default zoom option the default one
            productCtrl.zoomControlsOptions[1] = {
                isDefault: true,
                value: productCtrl.zoomControlsOptions[1]
            };

            _initCtrl(product, itemsData);

            Util.currentScopeListener($scope, $rootScope.$on('cart.lines.add', function () {
                productCtrl.product = Cart.getProduct(productCtrl.product);
                productCtrl.productSpecials = Util.filterProductSpecials(productCtrl.product.branch);
                productCtrl.hasClubSpecial = Util.productHasClubSpecial(productCtrl.product.branch);
            }));

            Util.currentScopeListener($scope, $rootScope.$on('document.keydown', function (data, event) {
                if (productCtrl.itemsData) {
                    if (event.which == 39) {
                        productCtrl.nextProduct();
                    }
                    if (event.which == 37) {
                        productCtrl.previousProduct();
                    }
                }
            }));

            Util.currentScopeListener($scope, $rootScope.$on('productDialog.change', function(event, data) {
                setFullScreen(false);
                _initCtrl(data.product, data.itemsData);
                productCtrl.similarProducts.reset();

                if (productCtrl.showProductInSeperatePage && !productCtrl.productNavigation) {
                    Dialog.hide();
                    window.history.replaceState('', '', location.origin + location.pathname)
                }
                productCtrl.productNavigation = false;
            }));

            $scope.$on('$destroy', function() {
                setFullScreen(false);
            });

            function _initCtrl(product, itemsData) {
                productCtrl.product = Cart.getProduct(product);
                productCtrl.itemsData = itemsData || null;   // if open product from products page send this to add next and previous buttons

                if (productCtrl.product.branch) {
                    productCtrl.productSpecials = Util.filterProductSpecials(productCtrl.product.branch);
                    productCtrl.hasClubSpecial = Util.productHasClubSpecial(productCtrl.product.branch);
                    productCtrl.hasOriginCountry = Config.retailer.settings.isAgProductsOriginFromPOSActive && productCtrl.product.branch.countryOfOrigin && (productCtrl.product.branch.countryOfOrigin[Config.language.id] || productCtrl.product.branch.countryOfOrigin[Config.retailer.languageId])
                } else {
                    productCtrl.productSpecials = null;
                    productCtrl.hasClubSpecial = false;
                }

                DataLayer.push(DataLayer.EVENTS.SELECT_ITEM, {products: [productCtrl.product]});
                DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Open Product Page'}});

                _initImages();
                _setCategoryType(productCtrl.product);
                _setProductPageCarouselContent();
                _setNutritionFacts();
                _setShopLists();
                _setProductTags();
                _setSupervisions();
                _setProductPageMessages();
                _setAttributeData();
                _setProductProperties();
                _setSeparateProductPage()
                /*_getSpecialsHeight();*/

                productCtrl.showSimilarProducts = true;
                if (productCtrl.itemsData && productCtrl.itemsData.siblings) {
                    loadSiblingsLargeImages(productCtrl.itemsData.siblings);
                }

                Util.updateMetaByProduct(productCtrl.product);

                DataLayer.push(DataLayer.EVENTS.VIEW_ITEM, {products: [productCtrl.product]});
            }

            function _setCategoryType(product) {
                return Util.getCategoryActualTypeByProductData(product).then(function(type) {
                    product.categoryType = type;
                });
            }

            function showProductTags(event, mode) {
                event.stopPropagation();
                productCtrl.showAllProductTags = mode;
            }

            function onPropertyValueChanged() {
                productCtrl.showPropertyError = null;

                var line = productCtrl.getLine();
                if (!productCtrl.selectedPropertyValue || !line) {
                    return;
                }
                productCtrl.selectedPropertyValue.propertyNames = productCtrl.productProperty.names;
                line.productPropertyValue = productCtrl.selectedPropertyValue;
                Cart.productPropertyValueChanged(line)
            }

            function back(){
                SpDialogUrlManager.backClose()
            }

            function navigateToCategory(category){
                $state.go('app.categoryProducts',{
                    cid: category.id,
                })
            }

            function _setSupervisions() {
                var supervisionMap = {};
                angular.forEach(SUPERVISION_TAGS, function(stringValue, identifier) {
                    supervisionMap[identifier] = [];
                });

                angular.forEach(productCtrl.product.supervisions, function(supervision) {
                    angular.forEach(supervision.tags, function(tag) {
                        supervisionMap[tag].push(supervision.names);
                    });
                });

                productCtrl.product.supervisionArray = [];
                productCtrl.hasSupervisions = false;

                angular.forEach(supervisionMap, function(value, key) {
                    if (value && value.length) {
                        productCtrl.hasSupervisions = true;
                    }

                    productCtrl.product.supervisionArray.push({
                        id: key,
                        values: value
                    });
                });
            }

            function _setProductPageMessages() {
                if( !(Config && Config.retailer && Config.retailer.productPageMessages) ) {
                    return {};
                }

                var response = {};

                //== Run for all messages and fill data for each message's language
                angular.forEach(Config.retailer.productPageMessages, function(message) {
                    response[message.languageId] = message;
                });
                productCtrl.productPageMessage = response[Config.language.id];
            }


            function _setAttributeData() {

                productCtrl.attributesList = [];

                if (productCtrl.product.data) {
                    var displayedAttributesList = _nameFilter(productCtrl.product.data, 'attributesList');
                    if (displayedAttributesList) {
                        productCtrl.attributesList = JSON.parse(displayedAttributesList);
                    }
                }
            }

            function _setProductTags() {
                var count = 0;
                productCtrl.productTags = [];
                angular.forEach(productCtrl.product.productTagsData, function(productTagRelation) {
                    if (SpProductTags.isProductTagRelationActive(productTagRelation) && productTagRelation.typeId !== PRODUCT_TAG_TYPES.LANDING_PAGE) {
                        productCtrl.productTags.push(productTagRelation);
                    }
                });
            }

            function _setSeparateProductPage(){
                productCtrl.showProductInSeperatePage = Config.retailer.settings.showProductInSeparatePage === 'true';
                if (productCtrl.showProductInSeperatePage){

                    // Fix for search input losing focus after click in dialog
                    angular.element(document.querySelector('.search-input')).on('focus', function () {
                        setTimeout(function() {
                            var activeElement;
                            if (!angular.element(document.activeElement).hasClass('search-input')) {
                                activeElement = document.activeElement;
                                activeElement.disabled = "disabled";
                                activeElement.style.visibility = "hidden";
                            }
                            document.getElementsByClassName('search-input')[0].focus();
                            document.getElementsByClassName('dialog-focus-trap')[0].scrollIntoView();
                            if (activeElement) {
                                activeElement.disabled = false;
                                activeElement.style.visibility = "visible";
                            }
                        });
                    });
                    var wrapper = angular.element(document.querySelector('.dialog-wrapper'));
                    var body = angular.element(document.querySelector('.dialog-body'));
                    var sidenav = angular.element(document.querySelector('.sidenav'));
                    var bubble = angular.element(document.querySelector('.home-page-bubble'));
                    window.scrollTo(0,body.offsetTop);
                    wrapper.addClass(SEPARATE_PRODUCT_PAGE_CLASS_NAME);
                    sidenav.addClass(HIDE_SIDENAV_CLASS_NAME)
                    bubble.addClass(HIDE_SIDENAV_CLASS_NAME)

                    Util.currentScopeListener($scope, $rootScope.$on('logout', function () {
                        back()
                    }));
                }
            }

            function _setProductProperties() {
                if (productCtrl.product.productProperties && productCtrl.product.productProperties.length) {
                    productCtrl.productProperty = productCtrl.product.productProperties[0];

                    var line = productCtrl.getLine();
                    if (line && line.productPropertyValue && line.productPropertyValue.id) {
                        productCtrl.selectedPropertyValue = productCtrl.productProperty.values.find(function (value) {
                            return value.id === line.productPropertyValue.id;
                        });
                    } else {
                        productCtrl.selectedPropertyValue = null
                    }
                }
            }

            function _setNutritionFacts() {
                productCtrl.nutritionFacts = {};
                angular.forEach(productCtrl.product.nutritionFacts, function (productNutritionFact) {
                    productNutritionFact.value = productNutritionFact.value ? productNutritionFact.value : 0;
                    productCtrl.nutritionFacts[productNutritionFact.nutritionFact.name[0].toLowerCase() + productNutritionFact.nutritionFact.name.substring(1)] = productNutritionFact;
                });
                if (productCtrl.nutritionFacts.totalFat) productCtrl.nutritionFacts.totalFat.dailyVal = productCtrl.nutritionFacts.totalFat.value ? ((productCtrl.nutritionFacts.totalFat.value / 65) * 100).toFixed() : 0;
                if (productCtrl.nutritionFacts.saturatedFat) productCtrl.nutritionFacts.saturatedFat.dailyVal = productCtrl.nutritionFacts.saturatedFat.value ? ((productCtrl.nutritionFacts.saturatedFat.value / 20) * 100).toFixed() : 0;
                if (productCtrl.nutritionFacts.transFat) productCtrl.nutritionFacts.transFat.dailyVal = productCtrl.nutritionFacts.transFat.value ? ((productCtrl.nutritionFacts.transFat.value / 20) * 100).toFixed() : 0;
                if (productCtrl.nutritionFacts.cholesterol) productCtrl.nutritionFacts.cholesterol.dailyVal = productCtrl.nutritionFacts.cholesterol.value ? ((productCtrl.nutritionFacts.cholesterol.value / 300) * 100).toFixed() : 0;
                if (productCtrl.nutritionFacts.sodium) productCtrl.nutritionFacts.sodium.dailyVal = productCtrl.nutritionFacts.sodium.value ? ((productCtrl.nutritionFacts.sodium.value / 2400) * 100).toFixed() : 0;
                if (productCtrl.nutritionFacts.totalCarbohydrates) productCtrl.nutritionFacts.totalCarbohydrates.dailyVal = productCtrl.nutritionFacts.totalCarbohydrates.value ? ((productCtrl.nutritionFacts.totalCarbohydrates.value / 300) * 100).toFixed() : 0;
                if (productCtrl.nutritionFacts.dietaryFiber) productCtrl.nutritionFacts.dietaryFiber.dailyVal = productCtrl.nutritionFacts.dietaryFiber.value ? ((productCtrl.nutritionFacts.dietaryFiber.value / 25) * 100).toFixed() : 0;
            }

            function _initImages() {
                productCtrl.images = _productImagesFilter(productCtrl.product);                
                productCtrl.shownImage = productCtrl.images[0];

                angular.forEach(productCtrl.images, function(image) {
                    if (image.typeId === IMAGE_TYPES.IMAGE) {
                        Util.loadImage(_imageFilter(image.url, 'large'));
                    }
                });
            }

            function _setProductPageCarouselContent() {
                productCtrl.productPageCarouselContent =
                    Config.retailer.productPageCarouselContent === PRODUCT_PAGE_CAROUSEL_CONTENT.SPECIAL_PRODUCTS &&
                    productCtrl.product.branch.specials && productCtrl.product.branch.specials.length && !productCtrl.product.branch.specials[0].hasOneProduct ?
                    PRODUCT_PAGE_CAROUSEL_CONTENT.SPECIAL_PRODUCTS : PRODUCT_PAGE_CAROUSEL_CONTENT.SIMILAR_PRODUCTS;
            }

            function _setShopLists() {
                productCtrl.isLoggedIn = false;
                productCtrl.shopLists = [];
                if (User.session.userId) {
                    productCtrl.isLoggedIn = true;
                    ShopListService.getShopListsWithItems(productCtrl.product.id, productCtrl.product.isCaseMode).then(function (data) {
                       productCtrl.savedToShopList = data.formattedNameList;
                       productCtrl.dontContainProductList = data.dontContainProductList;
                    });
                }
            }

            function createNewShopList() {
                ShopListService.create(productCtrl.shopListName).then(function () {
                    productCtrl.shopListName = '';
                    _setShopLists();
                });
            }

            function tryLogin() {
                if (productCtrl.isLoggedIn) {
                    return true;
                }
                Util.openLoginDialog();
                return false;
            }

            function saveToShopList(shopList) {
                if(productCtrl.product.productProperties && !productCtrl.selectedPropertyValue) {
                    productCtrl.showPropertyError = true;
                    return;
                }

                var line = productCtrl.getLine();
                if (line) {
                    if (line.product.quantity == 0) {
                        line.product.quantity = 1;
                    }
                } else {
                    line = {
                        product: productCtrl.product,
                        isCase: !angular.isUndefined(productCtrl.product.isCaseMode) ? productCtrl.product.isCaseMode : false,
                        comments: '',
                        productPropertyValueId: productCtrl.selectedPropertyValue ? productCtrl.selectedPropertyValue.id : null,
                        quantity: 1
                    };
                }
                $rootScope.$emit('cart.lines.added.to.mylist', [line.product.id]);
                var lines = [line];
                ShopListService.patchItems(shopList.Id, lines).then(function () {
                    if (productCtrl.savedToShopList) {
                        productCtrl.savedToShopList = productCtrl.savedToShopList + ', ' + shopList.Name;
                    } else productCtrl.savedToShopList = shopList.Name;

                    productCtrl.dontContainProductList = productCtrl.dontContainProductList.filter(function(item) {
                        return item.Id !== shopList.Id
                    });

                }).catch(function () {
                });
            }

            function getLine() {
                return Util.getProductCartLine(productCtrl.product, productCtrl.product.isCaseMode);
            }

            function loadSiblingsLargeImages(products) {
                angular.forEach(products, function(product) {
                    Util.loadImage(_productImageFilter(product, 'large'));
                });
            }

            /*function _getSpecialsHeight() {
                var productDetailsSelector = '.dialog-wrapper .dialog-body > .product-details > .product-details',
                    height = document.querySelector(productDetailsSelector).offsetHeight - 26,
                    heightElements = document.querySelectorAll([
                        productDetailsSelector + ' > .brand-and-weight',
                        productDetailsSelector + ' > .name',
                        productDetailsSelector + ' > .items-in-case',
                        productDetailsSelector + ' > hr',
                        productDetailsSelector + ' > div.bottom'
                    ].join(','));
                angular.forEach(heightElements, function(element) {
                    height -= element.offsetHeight;
                });
                productCtrl.specialHeight = height;
            }*/

            function _changeProduct(data) {
                productCtrl.showSimilarProducts = true;
                productCtrl.itemsData.index = 'index' in data && !angular.isUndefined(data.index) ? data.index : productCtrl.itemsData.index;
                productCtrl.itemsData.total = 'total' in data ? data.total : productCtrl.itemsData.total;
                if (data.product) {
                    openProduct(data.product, true);
                }
                if (data.siblings) {
                    productCtrl.itemsData.siblings = data.siblings;
                    loadSiblingsLargeImages(productCtrl.itemsData.siblings);
                }
            }

            function nextProduct() {
                productCtrl.productNavigation = true;
                productCtrl.itemsData.next(productCtrl.itemsData.index).then(_changeProduct);
                productCtrl.savedToShopList = false;
            }

            //== show HTML from backend's CKEditor
            function trustedHtml(text) {
                return $sce.trustAsHtml(text);
            }

            function previousProduct() {
                productCtrl.productNavigation = true;
                productCtrl.itemsData.previous(productCtrl.itemsData.index).then(_changeProduct);

                productCtrl.savedToShopList = false;
            }

            function openProduct(product, isSiblingNavigation) {
                if (productCtrl.showProductInSeperatePage){
                    productCtrl.productNavigation = true;
                }
                var event = {
                    action: 'Click'
                };
                if (isSiblingNavigation) {
                    event.category = 'Button';
                    event.label = 'Navigate between Product Pages';
                } else {
                    event.category = 'Link';
                    event.label = 'Similar Product in Product Page';
                }
                DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: event});

                if(product.item) {
                    product = product.item;
                }

                Util.openProduct(angular.isArray(product) ? product[0] : product, isSiblingNavigation ? productCtrl.itemsData : null);
            }

            function loadSimilarProducts(start, size, callback) {
                var loadSpecials = productCtrl.productPageCarouselContent === PRODUCT_PAGE_CAROUSEL_CONTENT.SPECIAL_PRODUCTS;
                if (!loadSpecials && !productCtrl.product.family) {
                    return;
                }

                var options = {
                    params: {
                        from: start,
                        size: size,
                        filters: {
                            must: {
                                term: {
                                    'branch.isActive': true,
                                    'branch.isVisible': true
                                }
                            },
                            mustNot: {
                                term: {
                                    id: productCtrl.product.id
                                }
                            }
                        }
                    }
                };

                if (Config.retailer.settings.showUnavailableProducts !== 'true') {
                    options.params.filters.mustNot.term["branch.regularPrice"] = 0;
                    options.params.filters.mustNot.term["branch.isOutOfStock"] = true;
                }
                else {
                    if (Config.retailer.settings.isOutOfStockDuration === 'true') {
                        options.params.filters.bool = {
                            should: [
                                {
                                    bool: {
                                        must_not: {
                                            exists: {
                                                field: "branch.outOfStockShowUntilDate"
                                            }
                                        }
                                    }
                                },
                                {
                                    bool: {
                                        must: [
                                            {
                                                range: {
                                                    "branch.outOfStockShowUntilDate": {
                                                        gt: "now"
                                                    }
                                                }
                                            },
                                            {
                                                term: {
                                                    "branch.isOutOfStock": true
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    bool: {
                                        must: [
                                            {
                                                term: {
                                                    "branch.isOutOfStock": false
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                }

                FilterManager.parseFilters(options.params, [_productTagsFilter]);

                if (loadSpecials) {
                    options.url = '/v2/retailers/:rid/branches/:bid/specials/' + productCtrl.product.branch.specials[0].id;
                } else {
                    options.url = '/v2/retailers/:rid/branches/:bid/products';
                    options.params.filters.must.term['family.id'] = productCtrl.product.family.id;
                }

                Api.request(options, {
                    fireAndForgot: true
                }).then(function (resp) {
                    if (loadSpecials) resp = resp.products;

                    resp.products = Cart.getProducts(resp.products);
                    productCtrl.showSimilarProducts = !!resp.total;
                    var chunks = resp.products;
                    if (_isRetailerPremiumReplacementsEnabled) {
                        var linesForSuggestions = [];
                        
                        angular.forEach(chunks, function (chunk) {

                            if (chunk && chunk.branch && chunk.branch.isOutOfStock) {
                                linesForSuggestions.push(chunk);
                            }
                        });

                        if (linesForSuggestions.length) {
                            Util.setReplacements(linesForSuggestions);
                        }

                    }
                    _setSimilarProductsCategoryType(chunks);
                    callback(null, resp.total, resp.products);
                }).catch(function (err) {
                    callback(err);
                });
            }

            function _setSimilarProductsCategoryType(products) {
                var promises = [];
                angular.forEach(products, function(product) {
                    if (product && product.productId) {
                        promises.push(_setCategoryType(product));
                    }
                });

                return $q.all(promises);
            }

            function setFullScreen(value) {
                productCtrl.isFullScreen = !!value;

                if (productCtrl.isFullScreen) {
                    angular.element(document.querySelector('.dialog.product .dialog-body')).addClass(FULL_SCREEN_CLASS_NAME);
                } else {
                    angular.element(document.querySelector('.dialog-body.' + FULL_SCREEN_CLASS_NAME)).removeClass(FULL_SCREEN_CLASS_NAME);
                }

                if (!productCtrl.isFullScreen && productCtrl.resetZoom) {
                    productCtrl.resetZoom();
                }
            }

            function image360ZoomOut(zoomIndex) {
                if (zoomIndex === 0) {
                    return false;
                }
                if (zoomIndex === undefined && productCtrl.isFullScreen) {
                    setFullScreen(false);
                }

                return true;
            }

            function _imageZoomOutFromFullScreen() {
                setFullScreen(false);
                return ZOOM_CONTROLS_ZOOM_OPTIONS[0];
            }
            function pricingMethodToggle(){
                _setShopLists();

            }

            function switchProductShowImage(chunk){
                var image = chunk[0];
                productCtrl.shownImage = image; 
                image.typeId === $rootScope.IMAGE_TYPES.IMAGE360 && productCtrl.setFullScreen(true);
            }

            function switchProductShowImage_onMouseEnter(chunk){
                var image = chunk[0];
                !productCtrl.isFullScreen && (productCtrl.shownImage = image);
            }

        }]);

})(angular, app);
