(function (angular, app) {
	"use strict";
	var isCancel = false;
	var TEMPLATE_ROUTE = "template/dialogs/update-order-v2";

	/**
	 * @typedef {Object} TabType
	 * @property {string} name - The name of the tab
	 * @property {string} templateUrl - The URL of the template for this tab
	 */

	/** @typedef {'LAUNCH_EDIT' | 'TIMESLOT' | 'IRRE_SALES' | 'FINISH'} TabName - The name of the tab */

	var TABS = {
		LAUNCH_EDIT: {
			name: 'LAUNCH_EDIT',
			templateUrl: TEMPLATE_ROUTE + "/launch-edit-order/index.html",
		},

		TIMESLOT: {
			name: "TIMESLOT",
			templateUrl: TEMPLATE_ROUTE + "/edit-time-slot/index.html",
		},

		IRRE_SALES: {
			name: "IRRE_SALES",
			templateUrl: TEMPLATE_ROUTE + "/irrelevant-sales/index.html",
		},

		FINISH: {
			name: "FINISH",
			templateUrl: TEMPLATE_ROUTE + "/finish/index.html",
		},
	};

	/**
	 * Config
	 **/
	function config(
		SpDialogUrlManagerProvider,
		SP_URL_DIALOG_QUERY_PARAMS,
		SP_URL_DIALOG_DATA_QUERY_PARAMS
	) {
		SpDialogUrlManagerProvider.dialog({
			queryParams: SP_URL_DIALOG_QUERY_PARAMS.UPDATE_ORDER_V2,
			dataQueryParams: SP_URL_DIALOG_DATA_QUERY_PARAMS.UPDATE_ORDER_V2,
			canShow: [
				"User",
				function (User) {
					// check can show edit flow order
					return User.session.userId;
				},
			],
			show: [
				"Dialog", "SpDialogUrlManager", "params", "Orders", "Cart",
				function (Dialog, SpDialogUrlManager, params, Orders, Cart) {
					var templateUrl = "template/dialogs/update-order-v2/index.html";
					var styleClass = "update-order-v2";
					var config = {
						templateUrl: templateUrl,
						controller: "UpdateOrderV2Ctrl as updateOrderV2Ctrl",
						styleClass: styleClass,
						disableClosing: false,
						ariaLabelledby: "update-order-title",
						// hide: SpDialogUrlManager.backClose,
						hide: function () {
							if (isCancel) {
								Orders.cancelEdit().then(function () {
									Cart.clear();
								}).catch(function () {
									Orders.orderInEdit = null;
									Cart.clear(true);
								});
							}
							SpDialogUrlManager.backClose()
						},
						resolve: {
							step: params.step,
							orderId: params.orderId,
							isCancelOnClose: params.isCancelOnClose
						},

					}
					return Dialog.show(config);
				}
			]
		})
	}

	/**
	 * Controller 
	 **/
	function ctrl($rootScope, $scope, config, SpDialogUrlManager, Orders, SP_SERVICES, Cart, Util,
		LocalStorage, step, orderId, isCancelOnClose, userService) {
		var updateOrderV2Ctrl = this;
		isCancelOnClose = (String(isCancelOnClose).toLowerCase() == 'true'); // parse to boolean
		isCancel = isCancelOnClose;

		// properties
		updateOrderV2Ctrl.tabsIndex = TABS
		updateOrderV2Ctrl.orderId = orderId;
		updateOrderV2Ctrl.isCancelOnClose = isCancelOnClose;
		updateOrderV2Ctrl.delivery = {};
		updateOrderV2Ctrl.area = {};
		updateOrderV2Ctrl.waitForSaveCart = false;
		updateOrderV2Ctrl.isNewPromotionDesignEnabled = $rootScope.config.retailer.settings.isNewPromotionDesignEnabled === 'true';
		updateOrderV2Ctrl.irreSalesData = Cart.irreSalesData || null
		updateOrderV2Ctrl.isChangeDeliveryTime = false;

		// function
		updateOrderV2Ctrl.close = close;
		updateOrderV2Ctrl.next = next;
		updateOrderV2Ctrl.back = back;
		updateOrderV2Ctrl.updateOrder = updateOrder;
		updateOrderV2Ctrl.backToHomePage = backToHomePage;

		_init();
		// ----------------------------------------------------
		function _init() {
			_setInitTab();
		}

		function _setInitTab() {
			var firstStep = TABS[step] || TABS.LAUNCH_EDIT;

			if (
				firstStep.name === TABS.IRRE_SALES.name &&
				(!updateOrderV2Ctrl.irreSalesData || !Orders.orderInEdit)
			) {
				firstStep = TABS.TIMESLOT;
			}

			setCurrentTab(firstStep.name);
		}

		function setCurrentTab(tabName) {
			if (!tabName) {
				return;
			}

			var cStep = TABS[tabName.toUpperCase()];
			if (!cStep) {
				return;
			}

			updateOrderV2Ctrl.previousTab = updateOrderV2Ctrl.currentTab || null;
			updateOrderV2Ctrl.currentTab = TABS[tabName];
		}

		function next() {
			switch (updateOrderV2Ctrl.currentTab.name) {
				case TABS.LAUNCH_EDIT.name:
					updateOrderV2Ctrl.isChangeDeliveryTime = true;
					goToEditTimeSlot();
					break;

				case TABS.TIMESLOT.name:
					checkUpdateOrder();
					break;
			}
		}

		function back() {
			if (updateOrderV2Ctrl.currentTab.name) {
				return setCurrentTab(TABS.TIMESLOT.name);
			}
		}

		/**
		 * @param {TabName} tabName 
		 * @param {TabName} tabName 
		 * @returns 
		 * @param {TabName} tabName
		 * @returns 
		 */
		function goToTab(tabName) {
			if (!tabName) {
				throw new Error("Tab Name invalid", tabName);
			}

			Util.openEditOrderDialog(tabName, isCancelOnClose, orderId);
			setCurrentTab(tabName);
		}

		/**
		 * @param {Object} options
		 * @param {boolean} options.shouldKeepEditMode
		 * @returns 
		 */
		function close(options) {
			Cart.setIrreSalesData(null)

			options = options || {};

			var isKeepEditMode =
				typeof options.shouldKeepEditMode === "boolean"
					? options.shouldKeepEditMode
					: isCancelOnClose;

			if (isKeepEditMode) {
				return SpDialogUrlManager.backClose();
			}

			Orders.cancelEdit()
				.then(function () {
					Cart.clear();
				})
				.catch(function () {
					Orders.orderInEdit = null;
					Cart.clear(true);
				});

			return SpDialogUrlManager.backClose();
		}

		function goToEditTimeSlot() {
			Orders.editOrder(updateOrderV2Ctrl.orderId).then(function () {
				goToTab(TABS.TIMESLOT.name);
			});
		}

		/**
		 * @return {Promise<void>}
		 */
		function checkUpdateOrder() {
			_removeOldAndAddDeliveryProductLine();

			if (updateOrderV2Ctrl.waitForSaveCart) {
				var removeCartUpdateListener = $rootScope.$on("cart.update.complete", function () {
					removeCartUpdateListener();
					handleCheckUpdateOrder();
				});
			} else {
				handleCheckUpdateOrder();
			}
		}

		/**
		 * @return {Promise<void>}
		 */
		function handleCheckUpdateOrder() {
			var curOrder = {
				id: Orders.orderInEdit.id,
				timePlaced: Orders.orderInEdit.timePlaced,
			};

			Cart.checkIrrelevantSales(updateOrderV2Ctrl.delivery.time, curOrder).then(function (irreSaleData) {
				if (!irreSaleData) {
					return updateOrder();
				}

				updateOrderV2Ctrl.irreSalesData = irreSaleData;
				goToTab(TABS.IRRE_SALES.name);
			});
		}

		function _resetUTCDate(date) {
			date = new Date(date);
			date.setUTCHours(0, 0, 0, 0);
			return date;
		}

		function updateOrder() {
			if (
				updateOrderV2Ctrl.delivery &&
				updateOrderV2Ctrl.delivery.time &&
				updateOrderV2Ctrl.delivery.time.id
			) {
				LocalStorage.setItem('updateTimeSlot-orderId-' + Orders.orderInEdit.id + '-cartId-' + Cart.serverCartId, {
					newAreaDeliveryTimeId: updateOrderV2Ctrl.delivery.time.id,
					newDate: _resetUTCDate(updateOrderV2Ctrl.delivery.time.newFrom),
					shippingTimeTo: updateOrderV2Ctrl.delivery.time.newTo,
					shippingTimeFrom: updateOrderV2Ctrl.delivery.time.newFrom,
					shippingTimeFromDisplay: updateOrderV2Ctrl.delivery.time.newFromDisplay
				});
			}

			$rootScope.$emit('order.update.timeslot', {
				isCancelOnClose: updateOrderV2Ctrl.isCancelOnClose,
				isChangeDeliveryTime: updateOrderV2Ctrl.isChangeDeliveryTime
			});
		}

		function _removeOldAndAddDeliveryProductLine() {
			// remove old delivery & service fee lines
			angular.forEach(Cart.lines, function (line) {
				if (line.type === SP_SERVICES.CART_LINE_TYPES.DELIVERY || line.type === SP_SERVICES.CART_LINE_TYPES.SERVICE_FEE) {
					line.removed = true;
					updateOrderV2Ctrl.waitForSaveCart = true;
					Cart.removeLine(line);
				}
			});

			// handle service fee
			if (config.retailer.serviceFeeProduct) {
				updateOrderV2Ctrl.waitForSaveCart = true;
				Cart.addLine({
					quantity: 1,
					type: SP_SERVICES.CART_LINE_TYPES.SERVICE_FEE,
					product: {
						id: config.retailer.serviceFeeProduct.id
					}
				});
			}

			// handle delivery
			var deliveryTimeProductId;
			if (updateOrderV2Ctrl.isShippingService) {
				deliveryTimeProductId = updateOrderV2Ctrl.area.providerDeliveryProductId;
			} else if (!updateOrderV2Ctrl.delivery.time) {
				deliveryTimeProductId = updateOrderV2Ctrl.area.deliveryProductId;
			} else {
				deliveryTimeProductId = updateOrderV2Ctrl.delivery.time.deliveryTimeProductId || updateOrderV2Ctrl.area.deliveryProductId;
			}

			if (deliveryTimeProductId) {
				updateOrderV2Ctrl.waitForSaveCart = true;
				Cart.addLine({
					quantity: Math.round(updateOrderV2Ctrl.delivery.time.price * 100),
					type: SP_SERVICES.CART_LINE_TYPES.DELIVERY,
					product: {
						id: deliveryTimeProductId
					}
				});
			}
		}

		function backToHomePage() {
			Util.updateOrderV2backToHomePage();
		}

		Util.currentScopeListener($scope, $rootScope.$on('order.update.timeslot.close', function (event, data) {
			close({ shouldKeepEditMode: true });
		}));

		Util.currentScopeListener($scope, $rootScope.$on('order.update.timeslot.finish', function (event, data) {
			goToTab(TABS.FINISH.name);
		}));

		Util.currentScopeListener($scope, $rootScope.$on('cart.update.ageRestriction.success', function (event, data) {
			var isUpdateDialogOpenning = !!document.querySelector('.update-order-v2');
			if (isUpdateDialogOpenning) {
				goToTab(TABS.TIMESLOT.name);
			}
		}));

		Util.currentScopeListener($scope, $rootScope.$on('cart.update.ageRestriction.failed', function (event, data) {
			var isValidCart = true;
			
			if (!userService.session.ageRestriction) {

                angular.forEach(Cart.lines, function (line) {
                	var validCartLine = false;
                    if (!line || !line.product || !line.product.branch || !line.product.branch.ageRestriction || line.product.branch.ageRestriction <= (userService.session && userService.session.ageRestriction || 0)) {
                    	validCartLine = true;
                    }
                    if(!validCartLine){
                    	isValidCart = false;
                    }
                });
            }

            if(isValidCart){
            	setTimeout(function(){
            		close({ shouldKeepEditMode: false });
            	}, 1000)	
				
			}
		}));
	}

	app
		.config([
			"SpDialogUrlManagerProvider",
			"SP_URL_DIALOG_QUERY_PARAMS",
			"SP_URL_DIALOG_DATA_QUERY_PARAMS",
			config,
		])
		.controller("UpdateOrderV2Ctrl", ['$rootScope', '$scope', 'Config', 'SpDialogUrlManager', 'Orders', 'SP_SERVICES', 'Cart', 'Util',
			'LocalStorage', 'step', 'orderId', 'isCancelOnClose','User',
			ctrl
		]);

})(angular, app);